.wrapper {
	margin: 0 auto;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
}

.header {
	position: relative;
	display: flex;
	align-items: center;
	gap: 4px;
}

.title {
	font-weight: 700;
	font-size: 20px;
}

.info {
	max-width: 327px;

	color: var(--hint-color);
	font-weight: 400;
	font-size: 16px;
	text-align: center;

	b {
		color: var(--text-color);
	}
}

.info_btn {
	background: transparent;
	width: 24px;
	height: 24px;
}

.link {
	color: var(--link-color);

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			text-decoration: underline;
		}
	}
}

.qrCode {
	width: 100%;

	margin: 4px 0 0 0;
	border-radius: 10px;
	padding: 26px;

	display: flex;
	flex-direction: column;
	align-items: center;
	background: var(--bg-color);

	p {
		margin: 16px 0 0 0;
		max-width: 160px;

		font-size: 14px;
		text-align: center;
		color: var(--hint-color);
	}
}

.code {
	width: 100%;

	border-radius: 10px;
	padding: 16px;

	display: flex;
	flex-direction: column;
	gap: 3px;

	background: var(--bg-color);
}

.codeTitle {
	text-align: center;
	font-size: 14px;
	color: var(--hint-color);
}

.codeCode {
	margin: 4px 0 0 0;
	font-family: "Share Tech Mono", monospace;
	font-weight: 400;
	font-size: 13px;
	text-align: center;
	word-break: break-all;

	cursor: pointer;
}

.codeCopy {
	margin: 12px 0 0 0;
	width: 100%;
}

.walletInfo_content {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
}

.walletInfo_info {
	max-width: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
}

.walletInfo_title {
	font-size: 20px;
	line-height: 1;
	font-weight: 600;
	text-align: center;
}

.walletInfo_text {
	font-size: 16px;
	color: var(--hint-color);
	text-align: center;
}

.walletInfo_btn {
	width: 100%;
	align-self: stretch;
}
