.wrapper {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.title {
	text-align: center;
	font-size: 20px;
	font-weight: 600;
}

.switchers {
	display: flex;
	gap: 12px;
}

.switch {
	padding-bottom: 6px;
	background: transparent;

	font-size: 20px;
	font-weight: 600;

	color: var(--hint-color);
	transition: color 0.2s ease;

	position: relative;

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background: var(--link-color);
		opacity: 0;
		transition: opacity 0.2s ease;
	}

	&.active {
		color: var(--link-color);

		&:after {
			opacity: 1;
		}
	}
}

.list {
	display: flex;
	flex-direction: column;

	background: var(--bg-color);
	border-radius: 10px;

	> div {
		position: relative;
		&::after {
			content: "";
			position: absolute;
			width: 80%;
			height: 1px;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			background: var(--hint-color);
			opacity: 0.3;
		}
		&:last-child::after {
			width: 0;
		}
	}
}

.empty {
	padding: 16px;
	border-radius: 10px;

	display: flex;
	align-items: center;
	gap: 8px;
	background: var(--bg-color);

	color: var(--hint-color);
}
