.wrapper {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.title {
	font-weight: 600;
	font-size: 17px;

	display: flex;
	align-items: center;
	gap: 4px;
}

.percent {
	padding: 0 6px;
	border-radius: 8px;
	font-weight: 300;
	font-size: 14px;
	color: var(--hint-color);
	background: #f5f5f8;
}

.days {
	font-weight: 400;
	font-size: 14px;
	color: var(--hint-color);
}
