.list {
	margin: 20px 0 0 0;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.item {
	border-radius: 16px;
	background: var(--bg-color);
}

.info {
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
}

.buttons {
	display: flex;
	align-items: center;
	gap: 15px;
}

.button {
	background: var(--secondary-bg-color);
	padding: 6px 10px;
	border-radius: 10px;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			background: #dfdfdf;
		}
	}
}

.yes {
	color: var(--green-color);
}

.no {
	color: var(--red-color);
}

.confirm {
	background: rgba($color: #000000, $alpha: 0.5);
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	display: flex;
	align-items: center;
	justify-content: center;
}
.confirm_content {
	border-radius: 24px;
	width: 350px;
	padding: 24px;
	background: var(--secondary-bg-color);
	text-align: center;

	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 24px;
}
.confirm_title {
	font-size: 24px;
	font-weight: 700;
}
.confirm_buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
}
.confirm_button {
	flex: 0 0 48%;
	width: 100%;
}
