.loader {
	width: 120px;
	aspect-ratio: 1/1;
	border: 10px solid var(--link-color);
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 2s linear infinite;
}

.small {
	width: 50px;
	border: 5px solid var(--link-color);
	border-bottom-color: transparent;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
