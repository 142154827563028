.title {
	font-size: 20px;
	font-weight: 700;
	text-align: center;
}

.link_wrapper {
	display: flex;
	justify-content: center;
}

.link {
	text-align: center;
	color: var(--link-color);
	background: transparent;
}

.second_title {
	margin: 18px 0 0 0;
	font-size: 18px;
	font-weight: 600;
}

.trans_list {
	margin: 8px 0 0 0;
	display: flex;
	flex-direction: column;

	background: var(--bg-color);
	border-radius: 10px;

	> div {
		position: relative;
		&::after {
			content: "";
			position: absolute;
			width: 80%;
			height: 1px;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			background: var(--hint-color);
			opacity: 0.3;
		}
		&:last-child::after {
			width: 0;
		}
	}
}

.value_info {
	margin: 6px 0 0 0;
	display: flex;
	align-items: center;
	gap: 10px;
}
.value_label {
	font-size: 14px;
}
.value_value {
	font-size: 15px;
	font-weight: 600;
}
