.title {
	font-weight: 600;
	font-size: 20px;
	text-align: center;
}

.content {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.holdings {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.list {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.item {
	display: flex;
	flex-direction: column;
	gap: 12px;

	padding: 12px;
	border-radius: 12px;
	background: var(--bg-color);
}

.name {
	display: flex;
	align-items: center;
	gap: 8px;

	font-weight: 600;
	font-size: 14px;
}

.image {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background: var(--text-color);
}

.dep {
	font-weight: 600;
	font-size: 14px;
}

.total {
	color: var(--green-color);
}

.rate {
	font-weight: 700;
	font-size: 20px;
	color: var(--green-color);

	display: flex;
	align-items: center;
	gap: 10px;

	.dot {
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background: var(--green-color);
	}
}

.info_label {
	margin: 0 0 6px 0;
	font-size: 14px;
	color: var(--hint-color);
}

.date {
	font-size: 14px;
	font-weight: 500;
	color: var(--link-color);
}

.empty {
	background: var(--bg-color);
	border-radius: 10px;
	padding: 16px;

	color: var(--hint-color);

	display: flex;
	align-items: center;
	gap: 8px;
}

.switchers {
	display: flex;
	gap: 12px;
}

.switch {
	padding-bottom: 6px;
	background: transparent;

	font-size: 20px;
	font-weight: 600;

	color: var(--hint-color);
	transition: color 0.2s ease;

	position: relative;

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background: var(--link-color);
		opacity: 0;
		transition: opacity 0.2s ease;
	}

	&.active {
		color: var(--link-color);

		&:after {
			opacity: 1;
		}
	}
}
