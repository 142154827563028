.balance {
	margin: 8px 0 0 0;
	font-size: 14px;

	display: flex;
	justify-content: space-between;
}

.input {
	margin: 12px 0 0 0;
}

.button {
	width: 100%;
	margin: 20px 0 0 0;
}

.name {
	сolor: var(--hint-color);
	margin: 0 0 8px 0;
	font-size: 12px;
	text-transform: uppercase;
}

.content {
	margin: 24px 0 0 0;
}

.list {
	display: flex;
	flex-direction: column;

	background: var(--bg-color);
	border-radius: 10px;

	> div {
		position: relative;
		&::after {
			content: "";
			position: absolute;
			width: 80%;
			height: 1px;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			background: var(--hint-color);
			opacity: 0.3;
		}
		&:last-child::after {
			width: 0;
		}
	}
}

.waiting {
	color: var(--hint-color);
}
.confirmed {
	color: var(--green-color);
}
.rejected {
	color: var(--red-color);
}

.send_block {
	margin: 16px 0 0 0;
}

.send_block_info {
	margin: 4px 0 0 0;
	font-size: 12px;
	text-align: center;
	color: var(--hint-color);
}

.popup {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
}

.popup_image {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: var(--green-color);

	display: flex;
	align-items: center;
	justify-content: center;
}

.popup_info {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
}

.popup_title {
	font-size: 20px;
	line-height: 1;
	font-weight: 600;
	text-align: center;
}

.popup_text {
	font-size: 16px;
	color: var(--hint-color);
	text-align: center;

	b {
		font-weight: 500;
		color: var(--text-color);
	}

	g {
		font-weight: 500;
		color: var(--green-color);
	}
}

.popup_btn {
	width: 100%;
	align-self: stretch;
}

.block {
	display: flex;
	gap: 10px;
	align-items: center;
}

.block_info {
	display: flex;
	flex-direction: column;
	gap: 2px;
	flex: 1 1 auto;

	p {
		font-weight: 400;
		font-size: 16px;
		color: var(--text-color);
	}

	& .blue {
		font-weight: 400;
		font-size: 14px;
		color: var(--link-color);

		display: flex;
		align-items: center;
		gap: 6px;
	}
}

.block_value {
	display: flex;
	flex-direction: column;
	gap: 2px;
	align-items: flex-end;

	p {
		&:first-child {
			font-weight: 400;
			font-size: 16px;
			text-align: center;
			color: var(--text-color);
		}
		&:last-child {
			font-weight: 400;
			font-size: 14px;
			text-align: center;
			color: var(--hint-color);
		}
	}
}

.qr_wrapper {
	position: relative;
}

.qr_button {
	position: absolute;
	top: 24px;
	right: 12px;
	background: transparent;
}
