.form {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.input {
	width: 100%;
	font-size: 16px;
	border-radius: 16px;

	padding: 8px 12px;
	background: var(--bg-color);
}

.button {
	width: 100%;
}
