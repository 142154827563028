.title {
	font-weight: 700;
	font-size: 20px;
}

.list {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 8px;
	margin-top: 12px;
}

.item {
	position: relative;
	border-radius: 10px;
	padding: 18px 16px;
	background: var(--bg-color);

	transition: transform 0.3s ease;

	display: flex;
	flex-direction: column;
	gap: 8px;

	&.hover {
		cursor: pointer;
		&:active {
			transform: scale(1.06);
		}
	}
}

.name {
	font-weight: 600;
	font-size: 14px;

	display: flex;

	align-items: center;
	gap: 8px;
}

.image {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background: var(--text-color);

	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.percent {
	font-weight: 700;
	font-size: 20px;
	color: var(--green-color);
}

.days {
	font-weight: 400;
	font-size: 14px;
	color: var(--hint-color);

	p {
		color: var(--link-color);
		font-weight: 500;
	}
}

.deposit {
	font-weight: 400;
	font-size: 14px;
	color: var(--hint-color);

	p:last-child {
		font-weight: 500;
		color: var(--text-color);
	}
}

.delete {
	position: absolute;
	top: 10px;
	right: 10px;
	color: var(--red-color);
	font-weight: 700;

	width: 20px;
	height: 20px;

	img {
		width: 100%;
		object-fit: contain;
	}
}
