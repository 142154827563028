.input {
	width: 100%;
	font-weight: 400;
	font-size: 14px;

	padding: 12px;
	border-radius: 10px;

	color: var(--text-color);
	background: var(--bg-color);

	border: 1.5px solid transparent;
	transition: border 0.3s ease;

	&.error {
		border: 1.5px solid #e01b1b;
	}
}

.input[type="number"] {
	-moz-appearance: textfield;
}
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.error_message {
	color: #e01b1b;
	font-size: 12px;

	margin: 6px 0 0 0;
}

.right_label_wrapper {
	position: relative;
}

.right_label {
	position: absolute;
	right: 12px;
	top: 50%;
	transform: translateY(-50%);

	color: var(--hint-color);
	font-size: 14px;
}
