.wrapper {
	background: var(--bg-color);

	margin-top: 8px;
	display: flex;
	justify-content: space-between;
	gap: 16px;
	border-radius: 20px;
}

.content {
	display: flex;
	flex-direction: column;
	gap: 8px;
	max-width: 217px;
	padding: 17px;

	p {
		font-weight: 700;
		font-size: 16px;
	}
}

.link {
	font-weight: 400;
	font-size: 14px;
	line-height: 0.8;
	color: var(--hint-color);

	display: flex;
	align-items: center;
	gap: 2px;
}

.image {
	margin: -8px -14px;
}
