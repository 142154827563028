.wrapper {
	position: fixed;
	bottom: 48px;
	right: 12px;
	left: 12px;

	padding: 8px 18px;

	font-weight: 400;
	font-size: 14px;

	border-radius: 10px;
	background: #2a2a2b;
	color: white;

	transition: all 0.3s ease;
	transform: translateY(120%);
	opacity: 0;

	display: flex;
	align-items: center;
	gap: 18px;
}

.opened {
	transform: translateY(0);
	opacity: 1;
}
