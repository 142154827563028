.wrapper {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.title {
	font-size: 24px;
	font-weight: 700;
	text-align: center;
}

.list {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;
}

.button {
	position: relative;
}

.count {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: var(--red-color);

	display: flex;
	align-items: center;
	justify-content: center;

	position: absolute;
	top: -6px;
	right: -6px;

	font-size: 14px;
}

.upcoming {
}
.upcoming_item {
	display: flex;
	align-items: center;
	gap: 30px;
}
.date {
	font-size: 14px;
}
.value {
	font-size: 16px;
	font-weight: 600;
}

.name {
	margin: 0 0 8px 0;
	font-size: 20px;
	font-weight: 600;
}

.balance_item {
	display: grid;
	align-items: center;
	grid-template-columns: 160px 1fr;
}

.balance {
	font-size: 18px;
}

.header {
	display: flex;
	align-items: center;
	gap: 30px;

	a {
		line-height: 1;
	}
}