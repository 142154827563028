.content {
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.header {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.balance {
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;

	p {
		font-weight: 700;
		font-size: 17px;
		line-height: 129%;
		letter-spacing: -0.02em;
		color: var(--hint-color);
	}
}

.balance_value {
	display: flex;
	align-items: center;
	gap: 8px;
}

.sum {
	font-weight: 600;
	font-size: 46px;
	letter-spacing: 0.01em;

	span {
		color: var(--hint-color);
	}
}

.refreshBtn {
	background: transparent;
}

.buttons {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.button {
	width: 100%;

	&.fw {
		grid-column: 1 / span 2;
	}
}

.stats {
	display: flex;
	align-items: center;
	gap: 28px;

	border-radius: 20px;
	padding: 8px 16px;
	background: var(--bg-color);

	box-shadow: inset 0 0 0 0 transparent;
	transition: box-shadow 0.3s ease;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			box-shadow: inset 0 0 18px -12px var(--text-color);
		}
	}

	&:active {
		box-shadow: inset 0 0 18px -4px var(--text-color);
	}
}

.statsImage {
	border-radius: 16px;
	padding: 12px;
}

.statsText {
	font-weight: 700;
	font-size: 16px;
}

.admin_button {
	font-size: 14px;

	position: fixed;
	top: 5px;
	right: 5px;

	background: var(--btn-color);
	opacity: 0.5;
	color: var(--btn-text-color);
	padding: 8px 12px;
	border-radius: 10px;
}

.transactions_btn {
	margin: 0 0 6px 0;
	background-color: var(--bg-color);
	border-radius: 50%;
	padding: 8px;

	width: 40px;
	height: 40px;
}

.status {
	margin: 12px 0 0 0;
	border-radius: 10px;
	padding: 14px 16px;
	background: var(--bg-color);
}
