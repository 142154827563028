.title {
	font-weight: 700;
	font-size: 20px;
	text-align: center;
}

.content {
	margin: 20px 0 0 0;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.item {
	display: flex;
	align-items: center;
	gap: 20px;
}

.label {
	flex: 0 0 200px;
	font-size: 16px;
	font-weight: 600;
}
