.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.title {
	font-size: 20px;
	font-weight: 700;
}

.item {
	margin: 12px 0 0 0;
	border-radius: 16px;
	padding: 16px;
	background: var(--bg-color);
}

.address {
	margin: 4px 0 0 0;
	font-family: "Share Tech Mono", monospace;
	font-weight: 400;
	font-size: 13px;
	word-break: break-all;

	cursor: pointer;
}

.balance {
	margin: 6px 0 0 0;
	font-size: 16px;
	font-weight: 600;
}
