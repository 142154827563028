.wrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 16px;
}

.icon {
	width: 60px;
	height: 60px;

	border-radius: 50%;
	background: #cc830d;

	display: flex;
	align-items: center;
	justify-content: center;

	path {
		stroke: white;
	}
}

.title {
	font-weight: 600;
	font-size: 20px;
	text-align: center;
	color: var(--text-color);
}

.info {
	font-weight: 400;
	font-size: 16px;
	text-align: center;
	color: var(--hint-color);
}
