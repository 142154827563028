.title {
	font-weight: 700;
	font-size: 20px;
}

.list {
	margin: 24px 0 0 0;
	display: flex;
	gap: 15px;
	flex-direction: column;
}

.item {
	background: var(--bg-color);

	padding: 20px;
	border-radius: 20px;
}

.item_head {
	display: flex;
	gap: 30px;
	align-items: center;
}

.date {
	font-size: 14px;
	color: var(--hint-color);
}

.item_info {
	margin: 10px 0 0 0;
	display: flex;
	gap: 6px;
	align-items: center;
}

.label {
	font-size: 14px;
}

.value {
	font-weight: 700;
}

.green {
	color: var(--green-color);
}

.loader {
	margin: 12px 0 0 0;
	display: flex;
	justify-content: center;
}