.title {
	text-align: center;
	font-size: 20px;
	font-weight: 600;
}

.content {
	margin: 16px 0 0 0;

	border-radius: 10px;
	background: var(--bg-color);
	padding: 15px;

	display: flex;
	flex-direction: column;
	gap: 8px;
}

.name {
	font-weight: 600;
	font-size: 14px;

	display: flex;
	align-items: center;
	gap: 8px;
}

.image {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: var(--text-color);

	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.rate {
	font-weight: 700;
	font-size: 20px;
	color: var(--green-color);

	display: flex;
	align-items: center;
	gap: 10px;
}

.dep {
	font-weight: 600;
	font-size: 14px;
}

.info_label {
	font-size: 14px;
	color: var(--hint-color);
}

.date {
	font-size: 14px;
	font-weight: 500;
	color: var(--link-color);
}

.button {
	margin: 16px 0 0 0;
	width: 100%;
}

.info {
	margin: 12px 0 0 0;
	padding: 16px;
	border-radius: 10px;

	font-size: 14px;
	font-weight: 500;
	background: var(--bg-color);
	color: var(--hint-color);

	display: grid;
	align-items: center;
	grid-template-columns: 24px 1fr;
	gap: 8px;
}

.form_wrapper {
	margin: 24px 0 0 0;
}

.form {
	margin: 12px 0 0 0;
}

.button {
	margin: 24px 0 0 0;
	width: 100%;
}

.input {
	padding-right: 60px;
}

.error {
	margin: 5px 0 0 0;
	text-align: center;
	font-size: 13px;
	font-weight: 600;
	color: var(--red-color);
}

.send_block_info {
	margin: 4px 0 0 0;
	font-size: 12px;
	text-align: center;
	color: var(--hint-color);
}

.balance {
	margin: 8px 0 0 0;
	display: flex;
	align-items: center;
	justify-content: space-between;

	font-size: 14px;
}

.balance_value {
	display: flex;
	align-items: center;
	gap: 4px;

	cursor: pointer;
}

.popup {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
}

.popup_image {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: var(--text-color);

	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.popup_info {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
}

.popup_title {
	font-size: 20px;
	line-height: 1;
	font-weight: 600;
	text-align: center;
}

.popup_text {
	max-width: 310px;
	font-size: 16px;
	color: var(--hint-color);
	text-align: center;
}

.popup_btn {
	width: 100%;
	align-self: stretch;
}

.amount,
.days,
.tool_name {
	font-weight: 600;
}

.amount {
	color: var(--green-color);
}

.tool_name {
	color: var(--text-color);
}

.days {
	color: var(--link-color);
}
