@import url("https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap");

@font-face {
	font-family: "SF Pro Display";
	font-style: normal;
	font-weight: 400;
	src: url("./assets/fonts/SF-Pro-Display-Regular.otf") format("opentype");
}

@font-face {
	font-family: "SF Pro Display";
	font-style: normal;
	font-weight: 500;
	src: url("./assets/fonts/SF-Pro-Display-Medium.otf") format("opentype");
}

@font-face {
	font-family: "SF Pro Display";
	font-style: normal;
	font-weight: 600;
	src: url("./assets/fonts/SF-Pro-Display-Semibold.otf") format("opentype");
}

@font-face {
	font-family: "SF Pro Display";
	font-style: normal;
	font-weight: 700;
	src: url("./assets/fonts/SF-Pro-Display-Bold.otf") format("opentype");
}

/* -------------------  Обнуление ------------------- */
* {
	margin: 0;
	padding: 0;
	border: 0;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

:focus,
:active {
	outline: none;
}

a:focus,
a:active {
	outline: none;
}

nav,
footer,
header,
aside {
	display: block;
}

html,
body {
	width: 100%;
	height: 100%;
	background: var(--secondary-bg-color);
	color: var(--text-color) !important;

	font-family: "SF Pro Display";
	font-size: 16px;

	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;

	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

body {
	position: relative;
}

input,
button,
textarea {
	font-family: inherit;
}

input,
textarea {
	overflow: hidden;
}

input::-ms-clear {
	display: none;
}

button {
	cursor: pointer;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

a,
a:visited,
a:hover {
	text-decoration: none;
}

img {
	vertical-align: top;
}

a,
label {
	-webkit-tap-highlight-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	font-weight: inherit;
}

table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
	margin: 0;
	padding: 0;
	border: none;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	border-collapse: separate;
}

table {
	border-collapse: separate;
	border-spacing: 0;
}

/* img,
video {
	pointer-events: none;

} */

:root {
	--bg-color: var(--tg-theme-bg-color);
	--secondary-bg-color: var(--tg-theme-secondary-bg-color, #dddde2);

	--btn-color: var(--tg-theme-button-color, #007aff);
	--btn-text-color: var(--tg-theme-button-text-color, #f4f4f7);

	--text-color: var(--tg-theme-text-color, #000);
	--accent-text-color: var(--tg-theme-accent-text-color, #007aff);
	--subtitle-text-color: var(--tg-theme-subtitle-text-color, #707579);

	--hint-color: var(--tg-theme-hint-color, #707579);
	--link-color: var(--tg-theme-link-color, #007aff);
	--red-color: var(--tg-theme-destructive-text-color);
	--green-color: #19b43f;
}

// var(--tg-theme-header-bg-color)
// var(--tg-theme-section-bg-color)
// var(--tg-theme-section-header-text-color)
// .
// .

.vibro_anim {
	animation: vibro 0.4s ease;
}

@keyframes vibro {
	0% {
		transform: rotate(1deg);
	}
	10% {
		transform: rotate(-1deg);
	}
	30% {
		transform: rotate(1deg);
	}
	40% {
		transform: rotate(-1deg);
	}
	50% {
		transform: rotate(1deg);
	}
	60% {
		transform: rotate(-1deg);
	}
	70% {
		transform: rotate(1deg);
	}
	80% {
		transform: rotate(-1deg);
	}
	90% {
		transform: rotate(1deg);
	}
}
